/* #about-section{
    margin-left: 15%;
} */

/* #about-section > .about-header{
    font-size: 30px;
    font-weight: bold;
    margin: auto;
    width: fit-content;
} */

#about-section > .about-me{
    display: grid;
    grid-template-areas: "description" "picture";
    margin: auto;
    background-color: var(--navy);
    /* grid-template-columns: 55% auto; */
    /* gap: 5%; */
    width: 80vw;
    /* border: var(--primary) 2px solid; */
    box-shadow: 10px 10px 8px rgb(0, 0, 0);
}

#about-section > .about-me > .about-me-description{
    color: mintcream;
    overflow-wrap: break-word;
    grid-area: description;
} 

#about-section > .about-me > .about-me-description > p{
    color: white;
    /* border: turquoise 2px solid; */
    width: 80%;
    margin: auto;
    padding: 15px 0px;
}

#about-section > .about-me > .about-me-description > p > a {
    color: var(--primary);
    text-decoration: none;
    background-image: linear-gradient(var(--primary), var(--primary));
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;
}

#about-section > .about-me > .about-me-description > p >
a:hover,
a:focus,
a:active {
    color: var(--primary);
    background-size: 100% 0.1em;
}

#about-section >.about-me > .my-pic-div{    
    display: grid;
    grid-area: picture;
}

#about-section >.about-me > .my-pic-div > img{
    width: 100%;
    transition: 0.5s;
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* #about-section >.about-me > .my-pic-div >:hover{
    border: 2px var(--primary) solid;
} */


/* For larger phones */
@media only screen and (min-width: 574px){
    #about-section >.about-me > .my-pic-div > img{
        width: 80%;
        height: 80%;
    }
}

@media only screen and (min-width: 768px){
    #about-section > .about-me{
        grid-template-areas: "picture description";
        grid-template-columns: 50% auto;
        width: 90vw;
    }

    #about-section > .about-me > .my-pic-div > img{
        width: 100%;
        height: 100%;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

@media only screen and (min-width: 992px){
    #about-section > .about-me{
        grid-template-columns: 45% auto;
        width: 80vw;
    }

    /* #about-section > .about-header{
        font-size: 60px;
    } */

    #about-section > .about-me > .my-pic-div > img{
        height: 700px;
    }

    #about-section > .about-me > .about-me-description > p{
        font-size: 18px;
    }
}

@media only screen and (min-width: 1300px){
    #about-section > .about-me > .about-me-description > p{
        font-size: 30px;
    }

    #about-section > .about-me > .my-pic-div > img{
        height: 100%;
    }
}