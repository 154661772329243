#not_found_section{
    display: grid;
    color: var(--primary);
}

#not_found_section > .frown{
    margin: auto;    
    margin-top: 100px;
    font-size: 140px;
}

#not_found_section > .not_found_error_code{
    margin: auto;
    font-size: 100px;
    font-weight: bold;
}

#not_found_section > .not_found{
    margin: auto;
    font-size: 20px;
}

#not_found_section > .not_found_description{
    margin: auto;
    margin-top: 15px;
    font-size: 12px;
}

#not_found_section > a{
    width: 10%;
    margin: auto;
    padding: 5px 15px;
    border: 2px solid var(--primary);
    border-radius: 5px;
    background-color: var(--transparent);
    color: var(--primary);
    transition: 0.3s;
    text-decoration: none;
    width: fit-content;
}

#not_found_section > a:hover{
    background-color: var(--primary-transparent);
}

@media screen and (min-width: 768px) {
    #not_found_section > .frown{
        font-size: 250px;
    }

    #not_found_section > .not_found_error_code{
        font-size: 175px;
    }
    
    #not_found_section > .not_found{
        font-size: 50px;
    }    

    #not_found_section > .not_found_description{
        font-size: 30px;
    }

    #not_found_section > a{
        font-size: 35px;
        border: 5px solid var(--primary);
    }
}

@media screen and (min-width: 1200px){
    #not_found_section > .frown{
        font-size: 100px;
    }

    #not_found_section > .not_found_error_code{
        font-size: 100px;
    }

    #not_found_section > .not_found{
        font-size: 25px;
    }    

    #not_found_section > .not_found_description{
        font-size: 15px;
    }

    #not_found_section > a{
        font-size: 20px;
        border: 2px solid var(--primary);
    }
    
}

@media screen and (min-width: 1600px) {
    #not_found_section > .frown{
        font-size: 250px;
        margin-top: 150px;
    }

    #not_found_section > .not_found_error_code{
        font-size: 175px;
    }

    #not_found_section > .not_found{
        font-size: 40px;
    }    

    #not_found_section > .not_found_description{
        font-size: 30px;
    }

    #not_found_section > a{
        font-size: 35px;
        border: 5px solid var(--primary);
    }
}

