.divider{
    width: 128px;
    height: 6px;
    border-radius: 10px;
    background-color: var(--primary);   
    
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
}

@media only screen and (min-width: 768px){
    .divider{
        margin: auto;
        margin-top: 70px;
        margin-bottom: 70px;
    }
}

@media only screen and (min-width: 768px){
    .divider{
        margin: auto;
        margin-top: 80px;
        margin-bottom: 80px;
    }
}