/* 
Scrollbar styling
*/

::-webkit-scrollbar {
    width: 4px;    
    height: 0px;  
    transition: 0.3s;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0); 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary); 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-transparent); 
}