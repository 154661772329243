.older_projects_header{
    margin: auto;
    width: fit-content;
    color: white;
    font-size: 22px;
    font-weight: bold;
}

.older_projects{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 15px 15px;

    width: 80vw;
    height: 450px;
    margin: auto;    
    margin-top: 20px;
    
    overflow-y: scroll;
}

@media screen and (min-width: 768px) {
    .older_projects_header{
        font-size: 40px;
    }
}

@media screen and (min-width: 1500px) {
    .older_projects_header{
        font-size: 60px;
    }

    .older_projects{
        width: 90vw;
        gap: 25px 15px;
    }
}