.mini_project_card{
    display: grid;
    grid-template-rows: 20% auto 20%;

    width: 95%;
    height: 350px;
    margin: auto;
    background-color: var(--navy-light);

    transition: 0.3s;
    border-radius: 10px;
    z-index: 2;
}

.mini_project_card:hover{
    transform: translateY(-10px);
    cursor: pointer;
}

.mini_project_card > .icon_wrapper{    
    display: flex;
    justify-content: space-between;

    /* border: 2px yellow solid; */
}

.mini_project_card > .icon_wrapper > .icon_wrapper_inner{
    display: grid;
    width: 30%;
    font-size: 40px;

    /* border: 2px solid red; */
    color: var(--primary);
}   

.mini_project_card > .icon_wrapper > .github_link_wrapper{
    display: grid;
    width: 20%;
    font-size: 25px;

    /* border: 2px solid peru; */
    color: white;
}

.mini_project_card > .icon_wrapper > .github_link_wrapper > .mini_icon{
    transition: 0.3s;
}

.mini_project_card > .icon_wrapper > .github_link_wrapper > .mini_icon:hover{
    transform: translateY(-5px);
    color: var(--primary);
}

.mini_icon{
    margin: auto;
}


/* Description styling */
.mini_project_card > .mini_description_wrapper{
    display: grid;
    /* border: 2px green solid; */
}

.mini_project_card > .mini_description_wrapper > .mini_description_inner{
    /* border: 2px darkgoldenrod solid; */

    width: 90%;
    height: 100%;

    margin: auto;
}

.mini_project_card > .mini_description_wrapper > .mini_description_inner > .title{
    font-size: 23px;
    font-weight: bold;

    /* border: 2px red solid; */
    color: white;

    transition: 0.3s;
}

.mini_project_card:hover .mini_description_wrapper > .mini_description_inner > .title{
    color: var(--primary);
}

.mini_project_card > .mini_description_wrapper > .mini_description_inner > .mini_description{
    color: white;
}

.mini_project_card > .mini_stack{
    display: flex;
    align-items: center;
    overflow-x: scroll;

    color: white;
    /* border: 2px white solid; */
    padding-left: 20px;
}

.mini_project_card > .mini_stack > .technology{
    margin-right: 10px;
}

@media screen and (min-width: 768px) {
    .mini_project_card{
        width: 48%;
        margin: unset;
    }
}

@media screen and (min-width: 1200px) {
    .mini_project_card{
        width: 32%;
    }
}

@media screen and (min-width: 1500px){
    .mini_project_card{
        width: 23%;
        height: 550px;
    }

    .mini_project_card > .mini_description_wrapper > .mini_description_inner > .title{
        font-size: 30px;
    }

    .mini_project_card > .mini_description_wrapper > .mini_description_inner > .mini_description{
        font-size: 20px;
    }

    .mini_project_card > .icon_wrapper > .icon_wrapper_inner{
        font-size: 60px;
    }

    .mini_project_card > .icon_wrapper > .github_link_wrapper{
        font-size: 50px
    }

    .mini_project_card > .mini_stack > .technology{
        font-size: 20px;
    }
}