.work-group{
    display: grid;
    width: 90vw;
    height: fit-content;
    margin: auto;
}

.work-titles{
    display: flex;
    overflow-x: scroll;   
}

.work-title{
    color: var(--primary);
    /* border: var(--primary) 2px solid; */
    padding: 10px 10px;
    white-space: nowrap;
    width: fit-content;
    transition: 0.3s;
}

.work-title-active{
    font-weight: bold;
    border: var(--primary) 2px solid;
    /* background-color: var(--primary-transparent); */
}

.work-description{
    padding-top: 15px;
    padding-left: 15px;
    color: var(--primary);
    /* border: 2px solid white; */
}

.work-description > ul > li { 
    color: var(--primary); 
    margin-bottom: 1rem;
} 

.work-description > ul > li > span { 
    color: var(--primary);
    
}

.work-title:hover{
    cursor: pointer;
    background-color: var(--primary-transparent);
}

.work-description > .business-name{
    font-weight: bold;
    font-size: 30px;
}

.work-description > .year-start-end{
    font-size: 20px;
}

.work-description > .position{
    font-style: italic;
    margin-bottom: 10px
}



/* Display For Tablets */
@media only screen and (min-width: 768px)  {
    .work-group{
        display: flex;
        flex-direction: row;
    }

    .work-titles{
        display: grid;
        width: fit-content;
        overflow-x: unset;
        border-right: var(--primary) solid 1px;
    }

    .work-title{
        padding: 10px 10px;
        height: fit-content;
        width: 100%;
    }

    .work-description{
        padding-top: 0px;
    }
}

@media only screen and (min-width: 992px)  {
    .work-group{
        width: 90vw;
    }
}

@media only screen and (min-width: 1300px) {
    .work-title{
        font-size: 30px;
    }

    .work-description > .business-name{
        font-size: 40px;
    }

    .work-description > .year-start-end{
        font-size: 30px;
    }

    .work-description > .position{
        font-size: 25px;
    }

    .work-description > ul > li{
        font-size: 25px;
    }
}