@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
:root{
    --primary: rgb(100, 247, 255);
    --primary-transparent: rgba(137, 207, 240, 0.3);
    --secondary: rgb(0, 4, 49);
    --secondary-transparent: rgba(0, 4, 49, 0.96);
    --cool-grey: #bebebe;
    --navy: rgb(0, 11, 49);
    --navy-light: rgb(30, 30, 30);
    --transparent: rgba(0, 0, 0, 0);
}
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */

body{
    background-color: var(--secondary);
    font-family: 'Open Sans', sans-serif;
}

.header{
    font-size: 30px;
    font-weight: bold;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
}

@media only screen and (min-width: 768px) {
    .header{
        font-size: 45px;
    }
}

@media only screen and (min-width: 992px) {
    .header{
        font-size: 50px;
    }
}

@media only screen and (min-width: 1300px){
    .header{
        font-size: 60px;
    }
}

.number{
    color: var(--primary);
}

/* 
Scrollbar styling
*/

::-webkit-scrollbar {
    width: 4px;    
    height: 0px;  
    -webkit-transition: 0.3s;  
    transition: 0.3s;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0); 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary); 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-transparent); 
}
/* #about-section{
    margin-left: 15%;
} */

/* #about-section > .about-header{
    font-size: 30px;
    font-weight: bold;
    margin: auto;
    width: fit-content;
} */

#about-section > .about-me{
    display: grid;
    grid-template-areas: "description" "picture";
    margin: auto;
    background-color: var(--navy);
    /* grid-template-columns: 55% auto; */
    /* gap: 5%; */
    width: 80vw;
    /* border: var(--primary) 2px solid; */
    box-shadow: 10px 10px 8px rgb(0, 0, 0);
}

#about-section > .about-me > .about-me-description{
    color: mintcream;
    overflow-wrap: break-word;
    grid-area: description;
} 

#about-section > .about-me > .about-me-description > p{
    color: white;
    /* border: turquoise 2px solid; */
    width: 80%;
    margin: auto;
    padding: 15px 0px;
}

#about-section > .about-me > .about-me-description > p > a {
    color: var(--primary);
    text-decoration: none;
    background-image: linear-gradient(var(--primary), var(--primary));
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;
}

#about-section > .about-me > .about-me-description > p >
a:hover,
a:focus,
a:active {
    color: var(--primary);
    background-size: 100% 0.1em;
}

#about-section >.about-me > .my-pic-div{    
    display: grid;
    grid-area: picture;
}

#about-section >.about-me > .my-pic-div > img{
    width: 100%;
    transition: 0.5s;
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* #about-section >.about-me > .my-pic-div >:hover{
    border: 2px var(--primary) solid;
} */


/* For larger phones */
@media only screen and (min-width: 574px){
    #about-section >.about-me > .my-pic-div > img{
        width: 80%;
        height: 80%;
    }
}

@media only screen and (min-width: 768px){
    #about-section > .about-me{
        grid-template-areas: "picture description";
        grid-template-columns: 50% auto;
        width: 90vw;
    }

    #about-section > .about-me > .my-pic-div > img{
        width: 100%;
        height: 100%;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

@media only screen and (min-width: 992px){
    #about-section > .about-me{
        grid-template-columns: 45% auto;
        width: 80vw;
    }

    /* #about-section > .about-header{
        font-size: 60px;
    } */

    #about-section > .about-me > .my-pic-div > img{
        height: 700px;
    }

    #about-section > .about-me > .about-me-description > p{
        font-size: 18px;
    }
}

@media only screen and (min-width: 1300px){
    #about-section > .about-me > .about-me-description > p{
        font-size: 30px;
    }

    #about-section > .about-me > .my-pic-div > img{
        height: 100%;
    }
}
.divider{
    width: 128px;
    height: 6px;
    border-radius: 10px;
    background-color: var(--primary);   
    
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
}

@media only screen and (min-width: 768px){
    .divider{
        margin: auto;
        margin-top: 70px;
        margin-bottom: 70px;
    }
}

@media only screen and (min-width: 768px){
    .divider{
        margin: auto;
        margin-top: 80px;
        margin-bottom: 80px;
    }
}
.horizontal{
    height: 1px;
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
    background-color: gray;
}
.hi{
    font-size: 20px;
    font-weight: bold;
    color: var(--primary);    
    margin: auto;    
    margin-top: 100px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.name{
    font-size: 35px;
    font-weight: bold;
    color: white;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.slogan{
    font-size: 30px;
    font-weight: bold;
    color: rgb(200, 200, 200);
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.description{
    color: gray;
    width: 90vw;
    /* overflow-wrap: break-word; */
    margin: auto;
}

.github-link-wrapper{
    margin: auto;
    margin-top: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.github-link{
    text-decoration: none;
    border: 3px solid var(--primary);
    border-radius: 5px;
    padding: 15px;
    color: var(--primary);
    transition: 0.3s;
}

.github-link:hover{
    background-color: var(--primary-transparent);
    color: var(--primary);
}

@media only screen and (min-width: 768px){

}

@media only screen and (min-width: 992px){
    .hi{
        font-size: 30px;   
    }

    .name{
        font-size: 50px;
    }

    .slogan{
        font-size: 50px;
    }

    .description{
        width: 60vw;
        font-size: 20px;
    }
}

@media only screen and (min-width: 1400px){
    .hi{
        font-size: 50px; 
        margin-top: 150px;  
    }

    .name{
        font-size: 70px;
    }

    .slogan{
        font-size: 70px;
    }

    .description{
        font-size: 30px;
    }

    .github-link{
        font-size: 30px;
        border: 4px solid var(--primary);
    }
}
.NewProjectCard_card_wrapper__1bUVX{
    display: grid;
    grid-template-areas: "picture" "description";

    width: 95vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 20px;
    gap: 20px;

    margin: auto;
    margin-bottom: 100px;
}

.NewProjectCard_project_image_wrapper__2-yhS{
    grid-area: picture;
}

.NewProjectCard_project_description_wrapper__38A-X{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: description;

    padding: 0px 10px;
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_project_name__QEoV2{
    font-weight: 800;
    font-size: 30px;
    color: aliceblue;
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_project_description__ipg_z{
    color: var(--primary);
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_tech_stack__2FL0x{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 0px;
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_tech_stack__2FL0x .NewProjectCard_stack__JATsw{
    border-radius: 10px;
    padding: 5px 5px;
    text-align: center;
    color: aliceblue;
    transition: 0.3s;

    &:hover{
        background-color: var(--primary-transparent);
    }
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_button_group__c60TX{
    display: flex;
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_button_group__c60TX a{
    display: flex;
    align-items: center;

    padding: 10px 15px;
    border-radius: 8px;
    transition: 0.3s;
    font-weight: 900;
    text-decoration: none;

    &:hover{
        color: aliceblue;
        border: 2px solid aliceblue;
    }
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_button_group__c60TX a .NewProjectCard_icon__1CHEG{
    margin-left: 10px;
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_button_group__c60TX .NewProjectCard_view_code__CEFIU{
    background-color: transparent;
    color: var(--primary);
    border: 2px solid var(--primary);

    margin-right: 30px;
}

.NewProjectCard_project_description_wrapper__38A-X .NewProjectCard_button_group__c60TX .NewProjectCard_demo__1z4j5{
    background-color: var(--primary);
    color: var(--secondary);

    border-radius: 8px;
    border: 2px solid var(--primary);

    margin-right: 30px;
}

.NewProjectCard_project_image_wrapper__2-yhS img{
    width: 100%;
    height: 100%;
    transition: 0.3s;

    &:hover{
        cursor: pointer;
        width: 105%;
        height: 105%;
    }
}

@media screen and (min-width: 992px) {
    .NewProjectCard_card_wrapper__1bUVX{
        width: 90vw;
    }

    .NewProjectCard_picture_left__tMDKx{
        grid-template-columns: 60% auto;
        grid-template-areas: "picture description";
    }
    
    .NewProjectCard_picture_right__52fpd{
        grid-template-columns: 40% auto;
        grid-template-areas: "description picture";
    }   
}
.mini_project_card{
    display: grid;
    grid-template-rows: 20% auto 20%;

    width: 95%;
    height: 350px;
    margin: auto;
    background-color: var(--navy-light);

    transition: 0.3s;
    border-radius: 10px;
    z-index: 2;
}

.mini_project_card:hover{
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    cursor: pointer;
}

.mini_project_card > .icon_wrapper{    
    display: flex;
    justify-content: space-between;

    /* border: 2px yellow solid; */
}

.mini_project_card > .icon_wrapper > .icon_wrapper_inner{
    display: grid;
    width: 30%;
    font-size: 40px;

    /* border: 2px solid red; */
    color: var(--primary);
}   

.mini_project_card > .icon_wrapper > .github_link_wrapper{
    display: grid;
    width: 20%;
    font-size: 25px;

    /* border: 2px solid peru; */
    color: white;
}

.mini_project_card > .icon_wrapper > .github_link_wrapper > .mini_icon{
    transition: 0.3s;
}

.mini_project_card > .icon_wrapper > .github_link_wrapper > .mini_icon:hover{
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    color: var(--primary);
}

.mini_icon{
    margin: auto;
}


/* Description styling */
.mini_project_card > .mini_description_wrapper{
    display: grid;
    /* border: 2px green solid; */
}

.mini_project_card > .mini_description_wrapper > .mini_description_inner{
    /* border: 2px darkgoldenrod solid; */

    width: 90%;
    height: 100%;

    margin: auto;
}

.mini_project_card > .mini_description_wrapper > .mini_description_inner > .title{
    font-size: 23px;
    font-weight: bold;

    /* border: 2px red solid; */
    color: white;

    transition: 0.3s;
}

.mini_project_card:hover .mini_description_wrapper > .mini_description_inner > .title{
    color: var(--primary);
}

.mini_project_card > .mini_description_wrapper > .mini_description_inner > .mini_description{
    color: white;
}

.mini_project_card > .mini_stack{
    display: flex;
    align-items: center;
    overflow-x: scroll;

    color: white;
    /* border: 2px white solid; */
    padding-left: 20px;
}

.mini_project_card > .mini_stack > .technology{
    margin-right: 10px;
}

@media screen and (min-width: 768px) {
    .mini_project_card{
        width: 48%;
        margin: unset;
    }
}

@media screen and (min-width: 1200px) {
    .mini_project_card{
        width: 32%;
    }
}

@media screen and (min-width: 1500px){
    .mini_project_card{
        width: 23%;
        height: 550px;
    }

    .mini_project_card > .mini_description_wrapper > .mini_description_inner > .title{
        font-size: 30px;
    }

    .mini_project_card > .mini_description_wrapper > .mini_description_inner > .mini_description{
        font-size: 20px;
    }

    .mini_project_card > .icon_wrapper > .icon_wrapper_inner{
        font-size: 60px;
    }

    .mini_project_card > .icon_wrapper > .github_link_wrapper{
        font-size: 50px
    }

    .mini_project_card > .mini_stack > .technology{
        font-size: 20px;
    }
}
.older_projects_header{
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    font-size: 22px;
    font-weight: bold;
}

.older_projects{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    grid-gap: 15px 15px;
    gap: 15px 15px;

    width: 80vw;
    height: 450px;
    margin: auto;    
    margin-top: 20px;
    
    overflow-y: scroll;
}

@media screen and (min-width: 768px) {
    .older_projects_header{
        font-size: 40px;
    }
}

@media screen and (min-width: 1500px) {
    .older_projects_header{
        font-size: 60px;
    }

    .older_projects{
        width: 90vw;
        grid-gap: 25px 15px;
        gap: 25px 15px;
    }
}
/* #work-section > .work-header{   
    margin: auto;
    width: fit-content;
    font-size: 30px;
    font-weight: bold;
    border: 2px solid red;
} */

/* @media only screen and (min-width: 992px){
    #work-section > .work-header{
        font-size: 60px;
    }
}

@media only screen and (min-width: 1400px){
    #work-section > .work-header{
        font-size: 80px;
    }
} */

.work-group{
    display: grid;
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}

.work-titles{
    display: flex;
    overflow-x: scroll;   
}

.work-title{
    color: var(--primary);
    /* border: var(--primary) 2px solid; */
    padding: 10px 10px;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.3s;
}

.work-title-active{
    font-weight: bold;
    border: var(--primary) 2px solid;
    /* background-color: var(--primary-transparent); */
}

.work-description{
    padding-top: 15px;
    padding-left: 15px;
    color: var(--primary);
    /* border: 2px solid white; */
}

.work-description > ul > li { 
    color: var(--primary); 
    margin-bottom: 1rem;
} 

.work-description > ul > li > span { 
    color: var(--primary);
    
}

.work-title:hover{
    cursor: pointer;
    background-color: var(--primary-transparent);
}

.work-description > .business-name{
    font-weight: bold;
    font-size: 30px;
}

.work-description > .year-start-end{
    font-size: 20px;
}

.work-description > .position{
    font-style: italic;
    margin-bottom: 10px
}



/* Display For Tablets */
@media only screen and (min-width: 768px)  {
    .work-group{
        display: flex;
        flex-direction: row;
    }

    .work-titles{
        display: grid;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        overflow-x: unset;
        border-right: var(--primary) solid 1px;
    }

    .work-title{
        padding: 10px 10px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
    }

    .work-description{
        padding-top: 0px;
    }
}

@media only screen and (min-width: 992px)  {
    .work-group{
        width: 90vw;
    }
}

@media only screen and (min-width: 1300px) {
    .work-title{
        font-size: 30px;
    }

    .work-description > .business-name{
        font-size: 40px;
    }

    .work-description > .year-start-end{
        font-size: 30px;
    }

    .work-description > .position{
        font-size: 25px;
    }

    .work-description > ul > li{
        font-size: 25px;
    }
}
#technologies-section > .technologies{
    display: grid;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
    width: 85vw;
}

#technologies-section > .technologies > .technology-wrapper > .technology{
    display: grid;
    border: 2px solid var(--primary);
    border-radius: 50px;
    height: 75px;
}

#technologies-section > .technologies > .technology-wrapper > .technology > img{
    margin: auto;
    height: auto;
    width: 45%;
}

#technologies-section > .technologies > .technology-wrapper > .technology-name{
    margin-top: 10px;
    text-align: center;
    color: var(--primary);
}

#technologies-section > p{
    margin: auto;
    color: gray;
    width: 80vw;
    overflow-wrap: break-word;
}


@media only screen and (min-width: 768px){
    #technologies-section > .technologies{
        grid-template-rows: none;
        grid-template-columns: repeat(5, 1fr);
        width: 95vw;
        grid-gap: 30px;
        gap: 30px;
    }

    #technologies-section > .technologies > .technology-wrapper > .technology{
        height: 125px;
    }
}

@media only screen and (min-width: 992px){
    #technologies-section > .technologies{
        width: 80vw;
        grid-column-gap: 40px;
        -webkit-column-gap: 40px;
                column-gap: 40px;
    }

    #technologies-section > .technologies > .technology-wrapper > .technology{
        height: 150px;
        transition: 0.3s;
    }

    #technologies-section > .technologies > .technology-wrapper > .technology:hover{
        background-color: #cfb99757;
    }

    #technologies-section > .technologies > .technology-wrapper > .technology > img {
        height: auto;
        width: 80px;
    }

    #technologies-section > .technologies > .technology-wrapper > .technology-name{
        font-size: 25px;
    }

    #technologies-section > p{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 20px;
    }
}

@media only screen and (min-width: 1300px){
    #technologies-section > .technologies{
        grid-gap: 75px;
        gap: 75px;
        width: 75vw;
    }

    #technologies-section > .technologies > .technology-wrapper > .technology{
        height: 200px;
        border: 4px solid var(--primary);
    }

    #technologies-section > .technologies > .technology-wrapper > .technology > img {
        height: auto;
        width: 120px;
    }

    #technologies-section > p{
        font-size: 30px;
    }
}
#contact_section{
    width: 90vw;
    /* border: 2px red solid; */
    margin: auto;
}

#contact_section > .contact_section_header{
    color: white;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 20px;
    font-weight: bold;
}

#contact_section > .contact_input{
    display: grid;
    /* border: 2px yellow solid;     */
    font-size: 20px;
    margin: 20px 0px;
    /* width: 100%; */
}

#contact_section > .contact_input > input{
    border: 2px solid var(--primary);
    background-color: var(--transparent);
    color: var(--primary);
    width: 100%;
    margin: auto;
    border-radius: 5px;
}

::-webkit-input-placeholder { 
    color: white;
    font-style: italic;
}

::placeholder { 
    color: white;
    font-style: italic;
}  

#contact_section > .contact_textarea{
    display: grid;
    /* border: 2px yellow solid; */
    margin: auto;
    margin: 20px 0px;
    font-size: 20px;

}

#contact_section > .contact_textarea > textarea{
    background-color: var(--transparent);
    border: 2px solid var(--primary);
    border-radius: 5px;
    color: var(--primary);
}

#contact_section > .send_message_button_wrapper{
    /* border: 2px solid orange; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

#contact_section > .send_message_button_wrapper > .send_message_button{
    margin: auto;
    border: 2px solid var(--primary);
    color: var(--primary);
    background-color: var(--transparent);
    border-radius: 10px;
    padding: 15px 15px;
    transition: 0.3s;
}

.loading{
    margin: auto;    
    margin-top: 150px;
    margin-bottom: 150px;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
}

.loading > img{
   height: 100px;
   width: 100px;
}

.error{
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 30px;
    color: red;
}

@media screen and (min-width: 768px) {
    #contact_section{
        width: 85vw;
    }

    #contact_section > .contact_section_header{
        font-size: 40px
    }

    #contact_section > .contact_input > input{
        font-size: 30px;
    }

    #contact_section > .contact_textarea > textarea{
        font-size: 30px;
    }

    
    #contact_section > .send_message_button_wrapper > .send_message_button{
        font-size: 30px;
    }
}

@media screen and (min-width: 1200px) {
    #contact_section{
        width: 65vw;
    }

    #contact_section > .contact_input > input{
        font-size: 20px;
    }

    #contact_section > .contact_textarea > textarea{
        font-size: 20px;
    }

    #contact_section > .send_message_button_wrapper > .send_message_button{
        margin: 20px 0px;
        font-size: 20px;
    }


    #contact_section > .send_message_button_wrapper > .send_message_button:hover{
        background-color: var(--primary-transparent);
    }

    .loading > img{
        height: 200px;
        width: 200px;
    }
}

@media screen and (min-width: 1500px){
    #contact_section > .contact_section_header{
        font-size: 60px;
    }

    #contact_section > .contact_input > input{
        font-size: 35px;
    }

    #contact_section > .contact_textarea > textarea{
        font-size: 35px;
    }

    #contact_section > .send_message_button_wrapper > .send_message_button{
        margin: 20px 0px;
        font-size: 35px;
    }

}
#footer_section{
    background-color: rgb(20, 20, 40);
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    padding: 20px 0px;
}

.fixed{
    position: fixed;
    left: 0;
    bottom: 0;
}

#footer_section > .socials{
    margin: auto;
}

#footer_section > .socials > .social_icon_link > .social_link{
    font-size: 25px;
    margin: 0px 20px;
    color: white;
    transition: 0.3s;
}

#footer_section > .socials > .social_icon_link > .social_link:hover{
    color: var(--primary);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

#footer_section > .copyright{
    margin: auto;
    color: white;
}

@media only screen and (min-width: 768px) {
    #footer_section > .socials > .social_icon_link > .social_link{
        font-size: 40px;
    }

    #footer_section > .copyright{
        font-size: 25px;
    }
}

@media only screen and (min-width: 1200px){
    #footer_section > .socials > .social_icon_link > .social_link{
        font-size: 35px;
    }

    #footer_section > .copyright{
        font-size: 15px;
    }
}

@media only screen and (min-width: 1500px) {
    #footer_section > .socials > .social_icon_link > .social_link{
        font-size: 60px;
    }

    #footer_section > .copyright{
        font-size: 30px;
    }
}

.autohide{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
}

.scrolled-down{
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%); 
    transition: all 0.2s ease-in-out;
}

.scrolled-up{
    -webkit-transform:translateY(0);
            transform:translateY(0); 
    transition: all 0.2s ease-in-out;
}

.link{
    margin-right: 1rem;
}

.link:hover{
    color: var(--primary);
}

/* .social-icon{
    display: none;
} */

#logo{
    margin-left: 2.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 45px;
    width: auto;
    transition: 0.3s;
}

#logo:hover{
    height: 55px;
}

#resume{
    border: 2px solid var(--primary);
    border-radius: 5px;
    margin-right: 2rem;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--primary);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#resume:hover{
    background-color: var(--primary-transparent);
}

nav{
    background-color: var(--secondary-transparent)
}

/* Large displays */
@media only screen and (min-width: 1600px) {
    .link{
        font-size: 30px;
    }

    #resume{
        font-size: 30px;
        border: solid 4px var(--primary);
    }

    #logo{
        height: 80px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #logo:hover{
        height: 90px;
    }

    .social-icon{
        font-size: 40px;
    }
}
.social-icon{
    font-size: 25px;
}


@media only screen and (min-width: 1200px){
    .social-icon{
        margin-left: 2rem;
        transition: 0.5s;
    }
    
    .social-icon:hover{
        color: var(--primary);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
}

@media only screen and (min-width: 1600px){
    .social-icon{
        font-size: 40px;
    }
}
#not_found_section{
    display: grid;
    color: var(--primary);
}

#not_found_section > .frown{
    margin: auto;    
    margin-top: 100px;
    font-size: 140px;
}

#not_found_section > .not_found_error_code{
    margin: auto;
    font-size: 100px;
    font-weight: bold;
}

#not_found_section > .not_found{
    margin: auto;
    font-size: 20px;
}

#not_found_section > .not_found_description{
    margin: auto;
    margin-top: 15px;
    font-size: 12px;
}

#not_found_section > a{
    width: 10%;
    margin: auto;
    padding: 5px 15px;
    border: 2px solid var(--primary);
    border-radius: 5px;
    background-color: var(--transparent);
    color: var(--primary);
    transition: 0.3s;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#not_found_section > a:hover{
    background-color: var(--primary-transparent);
}

@media screen and (min-width: 768px) {
    #not_found_section > .frown{
        font-size: 250px;
    }

    #not_found_section > .not_found_error_code{
        font-size: 175px;
    }
    
    #not_found_section > .not_found{
        font-size: 50px;
    }    

    #not_found_section > .not_found_description{
        font-size: 30px;
    }

    #not_found_section > a{
        font-size: 35px;
        border: 5px solid var(--primary);
    }
}

@media screen and (min-width: 1200px){
    #not_found_section > .frown{
        font-size: 100px;
    }

    #not_found_section > .not_found_error_code{
        font-size: 100px;
    }

    #not_found_section > .not_found{
        font-size: 25px;
    }    

    #not_found_section > .not_found_description{
        font-size: 15px;
    }

    #not_found_section > a{
        font-size: 20px;
        border: 2px solid var(--primary);
    }
    
}

@media screen and (min-width: 1600px) {
    #not_found_section > .frown{
        font-size: 250px;
        margin-top: 150px;
    }

    #not_found_section > .not_found_error_code{
        font-size: 175px;
    }

    #not_found_section > .not_found{
        font-size: 40px;
    }    

    #not_found_section > .not_found_description{
        font-size: 30px;
    }

    #not_found_section > a{
        font-size: 35px;
        border: 5px solid var(--primary);
    }
}


