.autohide{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
}

.scrolled-down{
    transform:translateY(-100%); 
    transition: all 0.2s ease-in-out;
}

.scrolled-up{
    transform:translateY(0); 
    transition: all 0.2s ease-in-out;
}

.link{
    margin-right: 1rem;
}

.link:hover{
    color: var(--primary);
}

/* .social-icon{
    display: none;
} */

#logo{
    margin-left: 2.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 45px;
    width: auto;
    transition: 0.3s;
}

#logo:hover{
    height: 55px;
}

#resume{
    border: 2px solid var(--primary);
    border-radius: 5px;
    margin-right: 2rem;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--primary);
    width: fit-content;
}

#resume:hover{
    background-color: var(--primary-transparent);
}

nav{
    background-color: var(--secondary-transparent)
}

/* Large displays */
@media only screen and (min-width: 1600px) {
    .link{
        font-size: 30px;
    }

    #resume{
        font-size: 30px;
        border: solid 4px var(--primary);
    }

    #logo{
        height: 80px;
        width: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #logo:hover{
        height: 90px;
    }

    .social-icon{
        font-size: 40px;
    }
}