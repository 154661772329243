.hi{
    font-size: 20px;
    font-weight: bold;
    color: var(--primary);    
    margin: auto;    
    margin-top: 100px;
    width: fit-content;
}

.name{
    font-size: 35px;
    font-weight: bold;
    color: white;
    margin: auto;
    width: fit-content;
}

.slogan{
    font-size: 30px;
    font-weight: bold;
    color: rgb(200, 200, 200);
    margin: auto;
    width: fit-content;
}

.description{
    color: gray;
    width: 90vw;
    /* overflow-wrap: break-word; */
    margin: auto;
}

.github-link-wrapper{
    margin: auto;
    margin-top: 2rem;
    width: fit-content;
}

.github-link{
    text-decoration: none;
    border: 3px solid var(--primary);
    border-radius: 5px;
    padding: 15px;
    color: var(--primary);
    transition: 0.3s;
}

.github-link:hover{
    background-color: var(--primary-transparent);
    color: var(--primary);
}

@media only screen and (min-width: 768px){

}

@media only screen and (min-width: 992px){
    .hi{
        font-size: 30px;   
    }

    .name{
        font-size: 50px;
    }

    .slogan{
        font-size: 50px;
    }

    .description{
        width: 60vw;
        font-size: 20px;
    }
}

@media only screen and (min-width: 1400px){
    .hi{
        font-size: 50px; 
        margin-top: 150px;  
    }

    .name{
        font-size: 70px;
    }

    .slogan{
        font-size: 70px;
    }

    .description{
        font-size: 30px;
    }

    .github-link{
        font-size: 30px;
        border: 4px solid var(--primary);
    }
}