.card_wrapper{
    display: grid;
    grid-template-areas: "picture" "description";

    width: 95vw;
    height: fit-content;
    gap: 20px;

    margin: auto;
    margin-bottom: 100px;
}

.project_image_wrapper{
    grid-area: picture;
}

.project_description_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: description;

    padding: 0px 10px;
}

.project_description_wrapper .project_name{
    font-weight: 800;
    font-size: 30px;
    color: aliceblue;
}

.project_description_wrapper .project_description{
    color: var(--primary);
}

.project_description_wrapper .tech_stack{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 0px;
}

.project_description_wrapper .tech_stack .stack{
    border-radius: 10px;
    padding: 5px 5px;
    text-align: center;
    color: aliceblue;
    transition: 0.3s;

    &:hover{
        background-color: var(--primary-transparent);
    }
}

.project_description_wrapper .button_group{
    display: flex;
}

.project_description_wrapper .button_group a{
    display: flex;
    align-items: center;

    padding: 10px 15px;
    border-radius: 8px;
    transition: 0.3s;
    font-weight: 900;
    text-decoration: none;

    &:hover{
        color: aliceblue;
        border: 2px solid aliceblue;
    }
}

.project_description_wrapper .button_group a .icon{
    margin-left: 10px;
}

.project_description_wrapper .button_group .view_code{
    background-color: transparent;
    color: var(--primary);
    border: 2px solid var(--primary);

    margin-right: 30px;
}

.project_description_wrapper .button_group .demo{
    background-color: var(--primary);
    color: var(--secondary);

    border-radius: 8px;
    border: 2px solid var(--primary);

    margin-right: 30px;
}

.project_image_wrapper img{
    width: 100%;
    height: 100%;
    transition: 0.3s;

    &:hover{
        cursor: pointer;
        width: 105%;
        height: 105%;
    }
}

@media screen and (min-width: 992px) {
    .card_wrapper{
        width: 90vw;
    }

    .picture_left{
        grid-template-columns: 60% auto;
        grid-template-areas: "picture description";
    }
    
    .picture_right{
        grid-template-columns: 40% auto;
        grid-template-areas: "description picture";
    }   
}